import React from 'react';
import OverboughtOversoldTabs from './OverboughtOversoldTabs.js';
import AppBar from './AppBar.js';

function OverboughtOversold(props) {
  return (
    <div>
      <AppBar {...props}/>
      <OverboughtOversoldTabs {...props}/>
    </div>
  );
}

export default OverboughtOversold;