import React from 'react';
import {TableBase} from './TableBase.js';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

function TabPanel(props) {
  const {children, value, index, ...other} = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    'id': `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export function ScoreTabs(props) {
  const [value, setValue] = React.useState(Number(localStorage.getItem('scoreTabValue')));


  const handleChange = (event, newValue) => {
    localStorage.setItem('scoreTabValue', newValue);
    setValue(newValue);
  };


  return (
    <div sx={{ flexGrow: 1, bgcolor: 'text.secondary'}}>
      <Paper square>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="score-tabs"
        >
          <Tab label="Score" {...a11yProps(0)} />
          <Tab label="Score History" {...a11yProps(1)} />

        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <TableBase
          dataEndpoint="/score"
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TableBase
          dataEndpoint="/score-history"
        />
      </TabPanel>
    </div>
  );
}

export default ScoreTabs;
